<template>
  <div>
    <div class="wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top: 30px;">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ourbrands' }">OurBrands</el-breadcrumb-item>
        <el-breadcrumb-item>PetGeek</el-breadcrumb-item>
      </el-breadcrumb>
      <img src="../assets/imgs/uploads/brandspet.jpg" alt="" class="brand">
      <p class="best">Best Sellers</p>
      <div style="display: flex;justify-content: space-between;margin-bottom: 60px;">
        <div class="goods" v-for="item in goods" :key="item.id">
          <div class="hot">
            Hot
          </div>
          <router-link :to="{ path: '/details', query: { spuId: item.id } }">
            <div style="margin-top: 23px;display: flex;justify-content: center;">
              <img v-lazy="item.spuImage" alt="" style="width: 210px;height: 210px;">
            </div>
            <div style="margin-top: 20px;display: flex;justify-content: center;font-weight: bold;font-size: 14px;">
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>
      <p class="best">Shop by Range</p>
      <router-link to="/goods">
        <div style="display: flex;justify-content: space-evenly;cursor: pointer;">
          <div v-for="item in cat" :key="item.id" class="range">
            <img :src="item.imgPath" style="width: 210px;height: 210px;border-radius: 20px;transition: all 0.6s;" alt="">
            <div class="tip">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: space-evenly;cursor: pointer;margin-top: 46px;">
          <div v-for="item in dog" :key="item.id" class="range">
            <img :src="item.imgPath" style="width: 210px;height: 210px;border-radius: 20px;transition: all 0.6s;" alt="">
            <div class="tip">
              {{ item.name }}
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="foot">
      <div class="wrapper" style="overflow: hidden;">
        <div style="margin: 76px 0 44px;display: flex;justify-content: center;">
          <img src="../assets/imgs/uploads/petlogo.png" style="width: 213px;height: 53px;" alt="">
        </div>
        <div style="color: #FFF;">
          <div style="text-align: center;">
            PetGeek specializing in pet toys and supplies, with a vision to raise awareness of pets' needs and welfare,
            and to create a happier and better environment for them. We offer high-quality products at affordable prices,
            ensuring that every pet enthusiast can easily access the necessary pet supplies. Our mission is to bring joy
            to more pets through toys, while also providing a more convenient lifestyle for both pets and their owners.
            Caring for pets and sharing happiness is our motto.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goods: [],
      cat: [
        { id: 1, imgPath: require('../assets/imgs/uploads/gigwicat1.jpg'), name: 'Cat' },
        { id: 2, imgPath: require('../assets/imgs/uploads/gigwicat2.jpg'), name: 'Cat Gift Box' },
        { id: 3, imgPath: require('../assets/imgs/uploads/gigwicat3.jpg'), name: 'Scratcher' },
        { id: 4, imgPath: require('../assets/imgs/uploads/gigwicat4.jpg'), name: 'Teaser Stick' },
        { id: 5, imgPath: require('../assets/imgs/uploads/gigwicat5.jpg'), name: 'Cat Tree' },
      ],
      dog: [
        { id: 1, imgPath: require('../assets/imgs/uploads/gigwidog1.jpg'), name: 'Dog' },
        { id: 2, imgPath: require('../assets/imgs/uploads/gigwidog2.jpg'), name: 'Dog Gift Box' },
        { id: 3, imgPath: require('../assets/imgs/uploads/gigwidog3.jpg'), name: 'Plush Toy' },
        { id: 4, imgPath: require('../assets/imgs/uploads/gigwidog4.jpg'), name: 'Chew Toy' },
        { id: 5, imgPath: require('../assets/imgs/uploads/gigwidog5.jpg'), name: 'Fetch&Retrive' },
      ],
    }
  },
  mounted() {
    this.getGigwiBestSells()
  },
  methods: {
    getGigwiBestSells() {
      this.$axios
        .post("/api/mall/home/getPETGEEKBestSells")
        .then(res => {
          this.goods = res.data.data;
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  },
}
</script>
<style scoped>
.wrapper {
  width: 1280px;
  margin: 0 auto;
}

.brand {
  width: 100%;
  height: 322px;
  margin-top: 30px;
}

.best {
  margin: 60px auto;
  font-family: 'Jomolhari';
  font-size: 36px;
  display: flex;
  justify-content: center;
}

.goods {
  width: 242px;
  height: 305px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.hot {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 33px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px 0 20px 0;
  color: #FFF;
  background-color: #FF764B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.range {
  width: 210px;
  height: 210px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.tip {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 17px;
  color: #FFF;
}

.range:hover img {
  transform: scale(1.3)
}

.foot {
  margin-top: 140px;
  width: 100%;
  height: 415px;
  background-color: #97CAEB;
}</style>